import React from "react";

const Error404 = () => {
    return (
        <div>
            Not found
        </div>
    )
}

export default Error404;